<template>
    <page
        id="settings"
        :title="translate('Settings')"
        :status="page_status"
        :is-root="true"
        @statused="statused"
    >
        <div v-if="!!edited_record && is_developer" class="columns">
            <div class="column col-6 col-sm-12 mb-2">
                <websites-list />
                <choice-lists class="mt-8" />
            </div>
            <div class="column col-6 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("Comparables Settings") }}</div>
                    </div>
                    <div class="card-body">
                        <div class="columns">
                            <form-input
                                id="comparables_price_delta"
                                name="comparables_price_delta"
                                v-model="edited_record.comparables_price_delta"
                                type="stripe"
                                :min="0"
                                glyph="dollar-sign"
                                :required="true"
                                :error="has_error('comparables_price_delta')"
                                class="column col-6 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:left_addon>
                                    <span class="input-group-addon">
                                        +/-
                                    </span>
                                </template>
                                <template v-slot:label>{{ translate("Price Range") }}</template>
                            </form-input>
                            <form-input
                                id="comparables_sale_delay"
                                name="comparables_sale_delay"
                                v-model="edited_record.comparables_sale_delay"
                                type="number"
                                :min="0"
                                :required="false"
                                :error="has_error('comparables_sale_delay')"
                                class="column col-6 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Go back at most") }}</template>
                                <template v-slot:right_addon>
                                    <span class="input-group-addon">
                                        {{ translate("day(s)") }}
                                    </span>
                                </template>
                            </form-input>
                            <form-toggle-input
                                id="comparables_major_area"
                                name="comparables_major_area"
                                type="checkbox"
                                v-model="edited_record.comparables_major_area"
                                :required="false"
                                class="column col-12"
                                @input="save"
                            >
                                {{ translate("Extend to Listing's Major Area") }}
                            </form-toggle-input>
                        </div>
                    </div>
                </div>
                <div class="card mt-8">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("Chance of Selling Default Values") }}</div>
                    </div>
                    <div class="card-body">
                        <div class="columns">
                            <form-input
                                id="default_chance_of_selling_baseline"
                                name="default_chance_of_selling_baseline"
                                v-model="edited_record.default_chance_of_selling_baseline"
                                type="float"
                                :min="0"
                                :max="100"
                                :float-step="0.5"
                                glyph="percent"
                                :required="true"
                                :error="has_error('default_chance_of_selling_baseline')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Starting Chance Of Selling") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_showings_count_trigger"
                                name="default_chance_of_selling_showings_count_trigger"
                                v-model="edited_record.default_chance_of_selling_showings_count_trigger"
                                type="number"
                                :min="0"
                                :required="true"
                                :error="has_error('default_chance_of_selling_showings_count_trigger')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Typical Nb of Showings") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_showing_weight"
                                name="default_chance_of_selling_showing_weight"
                                v-model="edited_record.default_chance_of_selling_showing_weight"
                                type="float"
                                :min="0"
                                :max="100"
                                :float-step="0.5"
                                glyph="percent"
                                :required="true"
                                :error="has_error('default_chance_of_selling_showing_weight')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Add'l Showing Weight") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_star_weight"
                                name="default_chance_of_selling_star_weight"
                                v-model="edited_record.default_chance_of_selling_star_weight"
                                type="float"
                                :min="0"
                                :max="100"
                                :float-step="0.5"
                                glyph="percent"
                                :required="true"
                                :error="has_error('default_chance_of_selling_star_weight')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Feedback Star Weight") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_dom_without_showings_trigger"
                                name="default_chance_of_selling_dom_without_showings_trigger"
                                v-model="edited_record.default_chance_of_selling_dom_without_showings_trigger"
                                type="number"
                                :min="0"
                                :required="true"
                                :error="has_error('default_chance_of_selling_dom_without_showings_trigger')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Days w/o Showings") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_dom_without_showings_day_weight"
                                name="default_chance_of_selling_dom_without_showings_day_weight"
                                v-model="edited_record.default_chance_of_selling_dom_without_showings_day_weight"
                                type="float"
                                :min="0"
                                :max="100"
                                :float-step="0.5"
                                glyph="percent"
                                :required="true"
                                :error="has_error('default_chance_of_selling_dom_without_showings_day_weight')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Add'l day w/o Showings Weight") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_dom_trigger"
                                name="default_chance_of_selling_dom_trigger"
                                v-model="edited_record.default_chance_of_selling_dom_trigger"
                                type="number"
                                :min="0"
                                :required="true"
                                :error="has_error('default_chance_of_selling_dom_trigger')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("DOM") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_dom_weight"
                                name="default_chance_of_selling_dom_weight"
                                v-model="edited_record.default_chance_of_selling_dom_weight"
                                type="float"
                                :min="0"
                                :max="100"
                                :float-step="0.5"
                                glyph="percent"
                                :required="true"
                                :error="has_error('default_chance_of_selling_dom_weight')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Add'l DOM Weight") }}</template>
                            </form-input>
                            <form-input
                                id="default_chance_of_selling_months_of_inventory_month_weight"
                                name="default_chance_of_selling_months_of_inventory_month_weight"
                                v-model="edited_record.default_chance_of_selling_months_of_inventory_month_weight"
                                type="float"
                                :min="0"
                                :max="100"
                                :float-step="0.5"
                                glyph="percent"
                                :required="true"
                                :error="has_error('default_chance_of_selling_months_of_inventory_month_weight')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Month of Inventory Weight") }}</template>
                            </form-input>
                            <form-input
                                id="market_change"
                                name="market_change"
                                v-model="edited_record.market_change"
                                type="float"
                                :min="-100"
                                :max="100"
                                :float-step="0.5"
                                glyph="percent"
                                :required="true"
                                :error="has_error('market_change')"
                                class="column col-3 col-lg-3 col-md-6 col-sm-12"
                                @input="save"
                            >
                                <template v-slot:label>{{ translate("Market Change") }}</template>
                            </form-input>
                        </div>
                    </div>
                </div>
                <div class="card mt-8">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("User Management") }}</div>
                    </div>
                    <div class="card-body">
                        <form-input
                            id="max_focus_areas"
                            name="max_focus_areas"
                            v-model="edited_record.max_focus_areas"
                            type="number"
                            :min="0"
                            :required="true"
                            :error="has_error('max_focus_areas')"
                            @input="save"
                        >
                            <template v-slot:label>{{ translate("Maximum Number of Focus Area(s)") }}</template>
                        </form-input>
                        <invitation-expiries
                            id="default_invitation_delay"
                            name="default_invitation_delay"
                            v-model="edited_record.default_invitation_delay"
                            :required="true"
                            :saving="saving('default_invitation_delay')"
                            :error="has_error('default_invitation_delay')"
                            @input="save"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="!!edited_record" class="columns">
            <div class="column col-6 col-sm-12 mb-2">
                <neighborhoods />
            </div>
            <div class="column col-6 col-sm-12 mb-2">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("Comparables Fineprint") }}</div>
                    </div>
                    <div class="card-body">
                        <form-editor
                            id="comparables_fineprint"
                            name="comparables_fineprint"
                            :data-version="edited_record.id"
                            v-model="edited_record.comparables_fineprint"
                            size="lg"
                            :required="false"
                            @input="save"
                        />
                    </div>
                </div>
                <div class="card mt-8">
                    <div class="card-header">
                        <div class="card-title h5">{{ translate("Email Signature") }}</div>
                    </div>
                    <div class="card-body">
                        <form-editor
                            id="email_signature"
                            name="email_signature"
                            :data-version="edited_record.id"
                            v-model="edited_record.email_signature"
                            size="lg"
                            :required="false"
                            @input="save"
                        />
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page } from "@/nibnut/mixins"

import { InvitationExpiries, FormInput, FormToggleInput, FormEditor } from "@/nibnut/components"
import { WebsitesList } from "@/custom/components"
import ChoiceLists from "./ChoiceLists"
import Neighborhoods from "./Neighborhoods"

export default {
    mixins: [is_record_editor_page],
    components: {
        InvitationExpiries,
        FormInput,
        FormToggleInput,
        FormEditor,
        WebsitesList,
        ChoiceLists,
        Neighborhoods
    },
    methods: {
        grab_record_id () {
            this.record_id = 1
        }
    },
    data () {
        return {
            entity: "setting",
            entityName: "Setting:::Settings"
        }
    }
}
</script>
