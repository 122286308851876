<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("Neighborhoods") }}</div>
        </div>
        <div class="card-body">
            <expandable
                v-for="major_area in major_areas"
                :key="major_area.id"
            >
                <template v-slot:title>
                    {{ major_area.name }}
                </template>
                <table class="table">
                    <tbody>
                        <tr
                            v-for="minor_area in minor_areas_for(major_area.id)"
                            :key="minor_area.id"
                            class="clickable"
                            @click.prevent="edit(minor_area)"
                        >
                            <td>{{ display_name(minor_area.name) }}</td>
                        </tr>
                    </tbody>
                </table>
            </expandable>
            <modal-dialog
                id="neighborhood-editor"
                :show.sync="editing"
            >
                <template v-slot:title>
                    <span class="h5">{{ display_name(edited_record.name) }}</span>
                </template>

                <metadata-input
                    v-if="!!edited_record && !!edited_record.metadata"
                    id="neighborhood-metadata"
                    :value="edited_record.metadata"
                    @input="save"
                />
            </modal-dialog>
        </div>
    </div>
</template>

<script>
// import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, is_record_editor } from "@/nibnut/mixins"

import {
    ModalDialog
} from "@/nibnut/components"
import {
    Expandable,
    MetadataInput
} from "@/custom/components"

export default {
    name: "Neighborhoods",
    mixins: [is_remote_data_table_source, is_record_editor],
    components: {
        ModalDialog,
        Expandable,
        MetadataInput
    },
    watch: {
        editing: "reload"
    },
    methods: {
        pre_load () {
            this.set_state_value("filter", this.constants("vocabularies", "VOCABULARY_NEIGHBORHOODS").id)
        },
        grab_record_id () {
        },
        minor_areas_for (major_area_id) {
            return this.rows.filter(neighborhood => neighborhood.parent_id === major_area_id)
        },
        display_name (name) {
            if(name) return name.replace(/^[a-zA-Z]{2}\s+/, "")
            return ""
        },
        edit (minor_area) {
            this.record_id = minor_area.id
            this.editing = true
        }
    },
    computed: {
        state_identifier () {
            return "neighborhoods-settings"
        },
        can_refresh () {
            return !!this.profile_id
        },
        can_reload () {
            return !!this.profile_id && !!this.editing
        },
        cleanup_before_reload () {
            return false
        },
        major_areas () {
            return this.rows.filter(neighborhood => {
                return !neighborhood.parent_id && (neighborhood.slug === "nanaimo")
            })
        }
    },
    data () {
        return {
            entity: "term",
            entityName: "neighborhood:::neighborhoods",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "vocabulary",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            editing: false
        }
    }
}
</script>
