<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h5">{{ $root.translate("List Values") }}</div>
        </div>
        <div class="card-body">
            <data-table
                id="choice-lists"
                :entity-name="entityName"
                :columns="columns"
                :rows="filtered_rows"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="is_at_least_administrator"
                :clickable="is_at_least_administrator"
                :show-head="false"
                :empty-title="translate('No values found in this list.')"
                @add="row_edit(null)"
                @click="row_edit($event)"
                @sort="sort_by"
                @search="search"
                @page="goto_page"
            >
                <template
                    v-slot:controls
                >
                    <div>
                        <form-dropdown
                            id="filter"
                            name="filter"
                            :value="state.filter"
                            :options="vocabularies"
                            :required="true"
                            @input="filter('vocabulary', $event)"
                        />
                    </div>
                </template>
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>
                        {{ row.name }}
                        <small
                            v-if="row.is_default"
                            class="text-gray ml-4"
                        >{{ translate("default") }}</small>
                    </h6>
                    <default-button
                        :block="true"
                        class="mt-4"
                        @click.prevent="row_edit(row)"
                    >
                        <open-icon glyph="pen" class="mr-2" />
                        {{ $root.translate("Edit") }}
                    </default-button>
                    <default-button
                        :block="true"
                        color="error"
                        class="mt-2"
                        @click.prevent.stop="confirm_record_delete(row)"
                    >
                        <open-icon glyph="trash" class="mr-2" />
                        {{ $root.translate("Delete") }}
                    </default-button>
                </template>
                <template
                    v-slot:tbody="{ row, field }"
                >
                    <div v-if="field === 'name'">
                        {{ row.name }}
                        <small
                            v-if="row.is_default"
                            class="text-gray ml-4"
                        >{{ translate("default") }}</small>
                    </div>
                    <div v-else-if="field === '_buttons'" class="text-right">
                        <default-button
                            color="error"
                            class="mr-4"
                            @click.prevent.stop="confirm_record_delete(row)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                        <default-button
                            @click.prevent="row_edit(row)"
                        >
                            <open-icon glyph="pen" />
                        </default-button>
                    </div>
                    <span v-else>{{ row[field] }}</span>
                </template>
            </data-table>

            <modal-dialog
                v-if="!!edited_record"
                id="choice-list-item-editor"
                :show.sync="editing"
            >
                <template v-slot:title>
                    <span v-if="!edited_record.id" class="h5">{{ $root.translate("New {vocabulary} Value", { vocabulary: current_vocabulary.name }) }}</span>
                    <span v-else class="h5">{{ $root.translate("Change {vocabulary} value \"{value}\"", { vocabulary: current_vocabulary.name, value: edited_record.name }) }}</span>
                </template>

                <p v-if="!!edited_record.id">{{ $root.translate("Change a value, in bulk, to another value. (Fix typos, rename an item, ...)") }}</p>
                <p v-if="!!edited_record.id" class="text-small mb-8">{{ $root.translate("If you need to merge two values together, simply change one of them to the other's value. (i.e. to merge 'Not Published' and 'Unpublished' so every record now uses 'Unpublished', edit 'Not Published' and change it to 'Unpublished')") }}</p>

                <form-input
                    id="name"
                    name="name"
                    v-model="action_row.name"
                    :error="has_error('name')"
                    :required="true"
                >
                    <template v-slot:label>
                        <span v-if="!edited_record.id">{{ $root.translate("Value:") }}</span>
                        <span v-else>{{ $root.translate("Change \"{value}\" to:", { value: edited_record.name }) }}</span>
                    </template>
                </form-input>

                <form-toggle-input
                    id="is_default"
                    name="is_default"
                    type="checkbox"
                    v-model="action_row.is_default"
                    :required="false"
                    :error="has_error('is_default')"
                >
                    {{ translate("Default Value") }}
                </form-toggle-input>

                <template v-slot:footer>
                    <div class="text-center">
                        <default-button
                            class="mx-2"
                            @click.prevent="row_edit_stop(false)"
                        >
                            {{ $root.translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="primary"
                            class="mx-2"
                            @click.prevent="row_edit_stop(true)"
                        >
                            {{ $root.translate("Save") }}
                        </default-button>
                        <p class="text-small text-error mt-2">{{ $root.translate("NOTE: there is no 'undo'. Double-check your value before saving.") }}</p>
                    </div>
                </template>
            </modal-dialog>
            <confirmation
                v-if="!!confirming"
                v-bind="confirmation_props"
                @cancel="done_confirming"
                @confirmed="confirmed"
            />
        </div>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, is_record_editor, confirms } from "@/nibnut/mixins"

import {
    ModalDialog,
    FormInput,
    FormDropdown,
    FormToggleInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "ChoiceLists",
    mixins: [is_remote_data_table_source, is_record_editor, confirms],
    components: {
        ModalDialog,
        FormInput,
        FormDropdown,
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        pre_load () {
            if(!this.data_version) this.set_state_value("filter", this.vocabularies[0].id)
        },
        grab_record_id () {
        },
        confirm_record_delete (row) {
            this.record_id = row.id
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete the value \"{value}\"", { value: row.name }),
                    message: this.$root.translate("Do you really want to delete this value? It will no longer be assigned to any records."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-row"
            )
        },
        confirmed () {
            if(this.confirming === "delete-row") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.record_id = 0
        },
        post_load () {
            if(this.editing) this.action_row = { ...this.edited_record, name: "" }
        },
        row_edit (row, parent_id = 0) {
            this.record_id = row ? row.id : 0
            this.parent_id = parent_id
            this.editing = true
            this.reload()
        },
        row_edit_stop (save) {
            const close = () => {
                this.editing = false
                this.action_row = {}
                this.parent_id = 0
                this.record_id = 0
                this.shell_record = null
            }
            if(save) {
                const data = {
                    vocabulary: this.action_row.vocabulary || this.state.filter,
                    parent_id: this.action_row.parent_id || this.parent_id,
                    is_default: this.action_row.is_default
                }
                if(this.action_row.name && (this.action_row.name !== this.edited_record.name)) data.name = this.action_row.name
                this.save_data_for_record_id(
                    this.entity,
                    this.edited_record.id,
                    data
                ).then(() => {
                    close()
                    this.refresh(true)
                })
            } else close()
        },
        vocabulary_is_hierarchical (vocabulary_id) {
            return [
                this.constants("vocabularies", "VOCABULARY_PROPERTY_TYPES").id,
                this.constants("vocabularies", "VOCABULARY_NEIGHBORHOODS").id
            ].indexOf(vocabulary_id) >= 0
        }
    },
    computed: {
        state_identifier () {
            return "choice-lists"
        },
        fields () {
            return ["fieldset::list"]
        },
        can_refresh () {
            return !!this.profile_id
        },
        can_reload () {
            return !!this.profile_id && this.editing
        },
        cleanup_before_reload () {
            return false
        },
        vocabularies () {
            return orderBy(
                Object.values(
                    this.constants("vocabularies")
                ).filter(vocabulary => {
                    return !this.vocabulary_is_hierarchical(vocabulary.id)
                }),
                "name",
                "asc"
            )
        },
        current_vocabulary () {
            if(!this.state.filter) return {}
            return Object.values(this.constants("vocabularies")).find(vocabulary => vocabulary.id === this.state.filter) || {}
        },
        current_vocabulary_is_hierarchical () {
            return this.vocabulary_is_hierarchical(this.current_vocabulary.id)
        }
    },
    data () {
        return {
            entity: "term",
            entityName: "list value:::list values",
            columns: {
                name: { label: "Value", sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "vocabulary",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            action_row: {},
            parent_id: 0,
            editing: false
        }
    }
}
</script>
